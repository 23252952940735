import { ServiceType } from "../services/types";

export enum EventPositionStatus {
  DRAFT = 'In Bearbeitung',
  OFFER_SENT = 'Angebot versendet',
  OFFER_ACCEPTED = 'Angebot angenommen',
  OFFER_DECLINED = 'Angebot abgelehnt',
  CHOSE_OTHER = 'Alternative gewählt',
  CONTRACT_SENT = 'Vertrag versendet',
  CONTRACT_RECEIVED = 'Vertrag erhalten',
  COMPLETED = 'Abgeschlossen',
  CANCELLED = 'Abgesagt',
}

export type EventPosition = {
  id: number;
  posNumber: number;
  event: Event;
  date: string;
  duration: string;
  start: string;
  end: string;
  place: string;
  address: string;
  zip_code: string;
  city: string;
  status: EventPositionStatus;
  name: string;
  type: ServiceType;
  description: string;
  price_net: number;
  price_gross: number;
  price_vat: number;
  invoiceId: number | undefined;
};

export type EventBundle = {
  bundleId: number;
  customerId: number;
};

export type Event = {
  bundle: EventBundle;
  position: EventPosition;
};

export type CreateEventPositionDto = {
  bundleId: number;
  eventPositionId?: number;
}

export type CreateEventDto = {
  customerId?: number;
};

export type UpdateEventDto = {
  positionId: number;
  bundle?: Partial<Omit<EventBundle, 'bundleId'>>;
  position?: Partial<Omit<EventPosition, 'id'>>;
};