import api from '../_app/api';
import { Service, UpdateServiceDto } from './types';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getServices: builder.query<Service[], void>({
      query: () => '/services',
      providesTags: (result) =>
        result
          ? [...result.map(({ id }: Service) => ({ type: 'Services' as const, id })), { type: 'Services', id: 'LIST' }]
          : [{ type: 'Services', id: 'LIST' }],
    }),
    getService: builder.query<Service, number>({
      query: (id: number) => `/services/${id}`,
      providesTags: (result, error, id) => [{ type: 'Services', id }],
    }),
    addService: builder.mutation<number, void>({
      query: () => ({
        url: '/services',
        method: 'POST',
        body: {},
      }),
      invalidatesTags: [{ type: 'Services', id: 'LIST' }],
    }),
    updateService: builder.mutation<void, UpdateServiceDto>({
      query: ({ id, ...updateDto }: UpdateServiceDto) => ({
        url: `/services/${id}`,
        method: 'PUT',
        body: updateDto,
      }),
      invalidatesTags: (result, error, eventService) => [{ type: 'Services', id: eventService.id }],
    }),
    deleteService: builder.mutation<void, number>({
      query: (id: number) => ({
        url: `/services/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Services', id }],
    }),
  }),
});

// Event Services
export const { useGetServicesQuery, useGetServiceQuery, useAddServiceMutation, useUpdateServiceMutation, useDeleteServiceMutation } =
  extendedApi;
