import { LoadingButton } from '@mui/lab';
import { TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useChangePasswordMutation, useUsername } from '../../auth/api';

const FIELD_VARIANT = 'filled';

type FormState = {
  oldPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
};

function ChangePasswordPanel() {
  const [formState, setFormState] = useState<Partial<FormState>>({});
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const username = useUsername();

  // UI state
  const disabled = isLoading || false;

  // Form controls
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value,
    }));
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    const requiredFields = ['oldPassword', 'newPassword', 'newPasswordRepeat'];
    const missingFields = requiredFields.filter((field) => !formState[field as keyof FormState] || formState[field as keyof FormState] === '');

    if (missingFields.length > 0) {
      enqueueSnackbar(`Bitte fülle alle Felder aus.`, { variant: 'error' });
      return;
    }

    if (formState.newPassword !== formState.newPasswordRepeat) {
      enqueueSnackbar(`Die Passwörter stimmen nicht überein.`, { variant: 'error' });
      return;
    }

    try {
      await changePassword({
        oldPassword: formState.oldPassword,
        newPassword: formState.newPassword,
      }).unwrap();
      enqueueSnackbar(`Das Passwort wurde erfolgreich geändert.`, { variant: 'success' });
      setFormState({});
    } catch (error: any) {
      enqueueSnackbar(`Das Passwort konnte nicht geändert werden: ${error.data.message}`, { variant: 'error' });
    }
  };

  return (
    <Grid2 container xs={12} rowSpacing={2}>
      <Grid2 xs={12}>
        <Typography variant="h5">Passwort ändern</Typography>
      </Grid2>
      <Grid2 xs={12}>
        <form noValidate autoComplete="off" onSubmit={onSubmit}>
          <Grid2 xs={6} direction="column" container rowSpacing={2}>
            <Grid2>
              <TextField
                variant={FIELD_VARIANT}
                fullWidth
                label="Benutzername"
                name="username"
                autoComplete="username"
                value={username}
                disabled={true}
              />
            </Grid2>
            <Grid2>
              <TextField
                variant={FIELD_VARIANT}
                type="password"
                fullWidth
                label="Altes Passwort"
                name="oldPassword"
                autoComplete="current-password"
                onChange={handleChange}
                value={formState.oldPassword || ''}
                disabled={disabled}
              />
            </Grid2>
            <Grid2>
              <TextField
                variant={FIELD_VARIANT}
                type="password"
                fullWidth
                label="Neues Passwort"
                name="newPassword"
                autoComplete="new-password"
                onChange={handleChange}
                value={formState.newPassword || ''}
                disabled={disabled}
              />
            </Grid2>
            <Grid2>
              <TextField
                variant={FIELD_VARIANT}
                type="password"
                fullWidth
                label="Neues Passwort (wiederholen)"
                name="newPasswordRepeat"
                onChange={handleChange}
                value={formState.newPasswordRepeat || ''}
                disabled={disabled}
              />
            </Grid2>
            <Grid2>
              <LoadingButton variant="contained" type="submit" name="submit" fullWidth color="primary" loading={isLoading} disabled={disabled}>
                Passwort ändern
              </LoadingButton>
            </Grid2>
          </Grid2>
        </form>
      </Grid2>
    </Grid2>
  );
}

export default ChangePasswordPanel;
