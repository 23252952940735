export const calculateVATFromNet = (price_net_raw: number) => {
  const price_net = Math.round(price_net_raw * 100) / 100;
  const price_vat = Math.round(price_net * 0.19 * 100) / 100;
  const price_gross = Math.round((price_net + price_vat) * 100) / 100;
  return { price_net, price_gross, price_vat };
}

export const calculateVATFromGross = (price_gross_raw: number) => {
  const price_gross = Math.round(price_gross_raw * 100) / 100;
  const price_net = Math.round((price_gross / 1.19) * 100) / 100;
  const price_vat = Math.round((price_gross - price_net) * 100) / 100;
  return { price_net, price_gross, price_vat };
}