import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import ChangePasswordPanel from './components/ChangePasswordPanel';

function SettingsModule() {
  return (
    <Grid2 container xs={4} rowSpacing={1}>
      <Grid2 xs={12}>
        <ChangePasswordPanel />
      </Grid2>
    </Grid2>
  );
}

export default SettingsModule;
