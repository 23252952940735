import api from '../_app/api';
import { Customer, UpdateCustomerDto } from './types';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCustomers: builder.query<Customer[], void>({
      query: () => '/customers',
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Customers' as const, id })), { type: 'Customers', id: 'LIST' }]
          : [{ type: 'Customers', id: 'LIST' }],
    }),
    getCustomer: builder.query<Customer, number>({
      query: (id) => `/customers/${id}`,
      providesTags: (result, error, id) => [{ type: 'Customers', id }],
    }),
    addCustomer: builder.mutation<number, void>({
      query: () => ({
        url: '/customers',
        method: 'POST',
        body: {},
      }),
      invalidatesTags: [{ type: 'Customers', id: 'LIST' }],
    }),
    updateCustomer: builder.mutation<void, UpdateCustomerDto>({
      query: ({ id, ...patch }) => ({
        url: `/customers/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Customers', id }],
    }),
    deleteCustomer: builder.mutation<void, number>({
      query: (id) => ({
        url: `/customers/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Customers', id }],
    }),
  }),
});

export const { useGetCustomersQuery, useGetCustomerQuery, useAddCustomerMutation, useUpdateCustomerMutation, useDeleteCustomerMutation } =
  extendedApi;
