import { Refresh } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { DataGrid, GridColDef, GridRowSelectionModel, GridToolbar, gridClasses } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../_app/store';
import { useGetCustomersQuery } from '../api';
import { customersSorted, customersColumnVisibilityChanged } from '../uiSlice';
import { CustomerContact } from '../types';

export interface CustomerTableProps {
  selectedEntityId?: number;
  setSelectedEntityId: (customerId: number | undefined) => void;
}

// Define table columns
const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 64 },
  { field: 'type', headerName: 'Art', width: 130 },
  { field: 'name', headerName: 'Name', width: 200 },
  { field: 'address', headerName: 'Addresse', width: 200 },
  { field: 'zip_code', headerName: 'Postleitzahl', width: 100 },
  { field: 'city', headerName: 'Stadt', width: 130 },
  { field: 'email', headerName: 'Email', width: 200 },
  { field: 'phoneNumber1', headerName: 'Telefonnummer 1', width: 160 },
  { field: 'phoneNumber2', headerName: 'Telefonnummer 2', width: 160 },
  { field: 'paymentType', headerName: 'Zahlungsart', width: 130 },
  {
    field: 'customerContacts',
    headerName: 'Kontaktpersonen',
    width: 130,
    // Display contact persons in separate lines
    valueGetter: (params) => {
      return params.row.contacts?.map((contact: CustomerContact) => contact.name).join('\n');
    },
  },
];

export function CustomersTable({ selectedEntityId, setSelectedEntityId }: CustomerTableProps) {
  const { data: customers, isFetching, isError, refetch } = useGetCustomersQuery();
  const sortModel = useSelector((state: RootState) => state.customersUI.customersSortingModel);
  const visibilityModel = useSelector((state: RootState) => state.customersUI.customersVisibilityModel);
  const dispatch = useDispatch();

  // Handle row selection
  const handleRowSelectionModelChanged = (selectionModel: GridRowSelectionModel) => {
    if (selectionModel.length === 1) {
      setSelectedEntityId(selectionModel[0] as number);
    } else {
      setSelectedEntityId(undefined);
    }
  };

  // Render
  return (
    <Grid2 xs={12} container rowSpacing={1}>
      <Grid2 xs={12}>
        <DataGrid
          density="compact"
          rows={customers || []}
          columns={columns}
          getRowId={(row) => row.id}
          checkboxSelection={false}
          autoHeight
          rowSelectionModel={selectedEntityId !== undefined ? [selectedEntityId] : []}
          onRowSelectionModelChange={handleRowSelectionModelChanged}
          keepNonExistentRowsSelected
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => dispatch(customersSorted(newSortModel))}
          slots={{
            toolbar: GridToolbar,
          }}
          columnVisibilityModel={visibilityModel}
          onColumnVisibilityModelChange={(newVisibilityModel) => {
            dispatch(customersColumnVisibilityChanged(newVisibilityModel));
          }}
          getRowHeight={() => 'auto'}
          sx={{
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
        />
      </Grid2>
      <Grid2 xs={12} container direction="row" columnSpacing={1} alignItems="center">
        <Grid2>
          <LoadingButton
            variant="text"
            size="small"
            color="info"
            startIcon={<Refresh />}
            onClick={() => setTimeout(refetch, 300)}
            loading={isFetching}
            loadingPosition="start"
          >
            Neu laden
          </LoadingButton>
        </Grid2>
        {isError && (
          <Grid2>
            <Typography variant="body2" color="error">
              Fehler: Kunden konnten nicht geladen werden.
            </Typography>
          </Grid2>
        )}
      </Grid2>
    </Grid2>
  );
}

export default CustomersTable;
