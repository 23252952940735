import { ModuleSpec } from '../_app/typing';
import CustomersModule from '../customers/Module';
import EventsModule from '../events/Module';
import ServicesModule from '../services/Module';
import SettingsModule from '../settings/Module';

const Modules: ModuleSpec[] = [
  {
    name: 'Kunden',
    path: 'customers',
    component: <CustomersModule />,
    pages: {},
  },
  {
    name: 'Auftritte',
    path: 'events',
    component: <EventsModule />,
    pages: {},
  },
  {
    name: 'Services',
    path: 'services',
    component: <ServicesModule />,
    pages: {},
  },
  {
    name: 'Einstellungen',
    path: 'settings',
    component: <SettingsModule />,
    pages: {},
  },
];

export default Modules;
