import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Typography variant="h1" component="h1" gutterBottom>
          404
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          Not Found
        </Typography>
        <Typography variant="body1" gutterBottom>
          Die Seite konnte nicht gefunden werden.
        </Typography>
        <Box sx={{ marginTop: 4 }}>
          <Button variant="text" color="primary" component={RouterLink} to="">
            Zurück zur Startseite
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default NotFoundPage;
