import { Button, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ModuleSpec, PageSpec } from '../../_app/typing';
import LocationBox from './LocationBox';

type NavMenuButtonProps = {
  module: ModuleSpec;
};

const cleanPath = (path: string) => {
  if (path.endsWith('/')) {
    return path.substring(0, path.length - 1);
  }
  return path;
}

const NavMenuButton: React.FC<React.PropsWithChildren<NavMenuButtonProps>> = ({ module, children }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  // Menu operations
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuItemClick = (page?: PageSpec) => {
    setAnchorEl(null);
    if (page) {
      navigate(module.path + '/' + page.path);
    }
  };

  // Pages to show in navigation
  const pages = Object.values(module.pages).filter((map) => !map.hidden)

  if (pages.length === 0) {
    return (
      <LocationBox location={module.path}>
        <Button color="inherit" component={Link} to={module.path}>
          {module.name}
        </Button>
      </LocationBox>
    );
  } else if (pages.length === 1) {
    return (
      <LocationBox location={cleanPath(module.path + '/' + pages[0].path)}>
        <Button color="inherit" component={Link} to={module.path + '/' + pages[0].path}>
          {module.name}
        </Button>
      </LocationBox>
    );
  } else {
    return (
      <React.Fragment>
        <LocationBox location={module.path}>
          <Button color="inherit" onClick={handleMenuClick}>
            {module.name}
          </Button>
        </LocationBox>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => handleMenuItemClick()}>
          {pages.map((page, index) => (
            <MenuItem key={index} onClick={() => handleMenuItemClick(page)}>
              {page.name}
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    );
  }
};

export default NavMenuButton;
