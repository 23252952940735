import { LoadingButton } from '@mui/lab';
import { FormControl, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useUpdateServiceMutation } from '../api';
import { Service, ServiceType } from '../types';

export type ServiceFormProps = {
  fromEntity?: Service;
  disabled?: boolean;
};

const FIELD_VARIANT = 'outlined';

const ServiceForm = (props: ServiceFormProps) => {
  // State
  const [service, setEventService] = useState<Partial<Service>>(props.fromEntity || {});
  React.useEffect(() => {
    setEventService(props.fromEntity || {});
  }, [props.fromEntity]);

  // Data
  const [updateService, { isLoading: isUpdating }] = useUpdateServiceMutation();

  // UI state
  const loading = isUpdating || false;
  const disabled = props.disabled || loading || false;

  // Form controls
  const onServiceChange = (event: React.ChangeEvent<{ name?: string; value: unknown }> | SelectChangeEvent | SelectChangeEvent<number>) => {
    const name = event.target.name as keyof typeof service;

    if (name === 'price_gross') {
      if (event.target.value === '') {
        setEventService({ ...service, price_gross: undefined });
      } else {
        const gross = Math.round((event.target.value as number) * 100) / 100;
        setEventService({ ...service, [name]: gross });
      }
    } else {
      setEventService({ ...service, [name]: event.target.value });
    }
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!props.fromEntity) {
      return;
    }

    updateService({ ...service, id: props.fromEntity.id })
      .unwrap()
      .then(() => {
        enqueueSnackbar(`Service '${service.name}' wurde gespeichert.`, { variant: 'success' });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Beim Speichern ist ein Fehler aufgetreten.', { variant: 'error' });
      });
  };

  return (
    <form noValidate autoComplete="off" onSubmit={onSubmit}>
      <Grid2 direction="column" container rowSpacing={2}>
        <Grid2>
          <TextField
            variant={FIELD_VARIANT}
            fullWidth
            size="small"
            label="Name"
            value={service.name || ''}
            onChange={onServiceChange}
            name="name"
            disabled={disabled}
          />
        </Grid2>
        <Grid2>
          <FormControl fullWidth size="small">
            <InputLabel id="type-label" variant={FIELD_VARIANT}>
              Art
            </InputLabel>
            <Select
              labelId="type-label"
              id="type"
              value={service.type || ''}
              onChange={onServiceChange}
              name="type"
              label="Art"
              variant={FIELD_VARIANT}
              disabled={disabled}
            >
              <MenuItem value={ServiceType.Event}>Auftritt</MenuItem>
              <MenuItem value={ServiceType.EventService}>Eventservice</MenuItem>
            </Select>
          </FormControl>
        </Grid2>
        <Grid2 xs={12} container columnSpacing={1}>
          <Grid2 xs={6}>
            <TextField
              variant={FIELD_VARIANT}
              fullWidth
              size="small"
              label="Dauer"
              value={service.duration || ''}
              onChange={onServiceChange}
              name="duration"
              disabled={disabled}
            />
          </Grid2>
          <Grid2 xs={6}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={service.price_gross || ''}
              name="price_gross"
              label="Preis (brutto)"
              onChange={onServiceChange}
              disabled={disabled}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
          </Grid2>
        </Grid2>
        <Grid2>
          <TextField
            variant={FIELD_VARIANT}
            multiline
            rows={6}
            fullWidth
            label="Beschreibung"
            value={service.description || ''}
            onChange={onServiceChange}
            name="description"
            disabled={disabled}
          />
        </Grid2>
        <Grid2 xs={12}>
          <LoadingButton type="submit" name="submit" variant="contained" fullWidth loading={loading} disabled={disabled}>
            Speichern
          </LoadingButton>
        </Grid2>
      </Grid2>
    </form>
  );
};

export default ServiceForm;
