import { Add, Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useConfirm } from 'material-ui-confirm';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAddServiceMutation, useDeleteServiceMutation, useGetServiceQuery } from '../api';
import ServiceForm from './ServiceForm';

export interface ServicesControlCardProps {
  selectedEntityId?: number;
  setSelectedEntityId: (serviceId: number | undefined) => void;
}

function ServicesControlCard({ selectedEntityId, setSelectedEntityId }: ServicesControlCardProps) {
  // State
  const navigate = useNavigate();
  const confirm = useConfirm();

  // Data
  const [addService, { isLoading: isAdding }] = useAddServiceMutation();
  const [deleteService, { isLoading: isDeleting }] = useDeleteServiceMutation();
  const { data: remoteService, isFetching, isSuccess, isError } = useGetServiceQuery(selectedEntityId!, { skip: !selectedEntityId });
  const service = selectedEntityId !== undefined ? remoteService : undefined;

  // UI state
  const disabled = !service || false;

  // Update state if selected entity successfully loaded but empty returned
  React.useEffect(() => {
    if (isError || (!isFetching && isSuccess && !remoteService)) {
      setSelectedEntityId(undefined);
    }
  }, [remoteService, isSuccess, isFetching, isError, setSelectedEntityId]);

  // Modification controls
  const onServiceAdd = () => {
    addService()
      .unwrap()
      .then((id) => {
        enqueueSnackbar(`Service wurde erstellt.`, {
          variant: 'success',
        });
        setSelectedEntityId(id);
      })
      .catch((error) => {
        enqueueSnackbar('Beim Erstellen ist ein Fehler aufgetreten.', { variant: 'error' });
      });
  };

  const onServiceDelete = () => {
    if (selectedEntityId) {
      confirm({ title: `Service '#${service?.id} - ${service?.name}' löschen?` }).then(() => {
        deleteService(selectedEntityId)
          .unwrap()
          .then(() => {
            enqueueSnackbar('Position erfolgreich gelöscht', { variant: 'success' });
            navigate(-1);
          })
          .catch(() => {
            enqueueSnackbar('Beim Löschen ist ein Fehler aufgetreten.', { variant: 'error' });
          });
      });
    }
  };

  return (
    <Box
      sx={{
        borderRadius: '4px',
        border: '1px solid #e0e0e0',
        height: '100%',
        width: '100%',
      }}
    >
      <Grid2 xs={12} container rowSpacing={2} padding={2}>
        <Grid2 xs={12} container justifyContent="space-between">
          <Grid2>
            <Typography variant="h6">{service ? `Service ${service?.id}` : 'Service'}</Typography>
          </Grid2>
          <Grid2 container columnSpacing={1}>
            <Grid2>
              <LoadingButton variant="outlined" onClick={onServiceAdd} color="secondary" startIcon={<Add />} loading={isAdding}>
                Neu
              </LoadingButton>
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2 xs={12}>
          <ServiceForm fromEntity={service} disabled={disabled} />
        </Grid2>
        <Grid2 xs={12}>
          <Divider />
        </Grid2>
        <Grid2 xs={12}>
          <LoadingButton
            variant="outlined"
            fullWidth
            onClick={onServiceDelete}
            color="primary"
            startIcon={<Delete />}
            loading={isDeleting}
            disabled={disabled}
          >
            Löschen
          </LoadingButton>
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default ServicesControlCard;
