import { deDE as coreDeDE } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { deDE as gridDeDE } from '@mui/x-data-grid';
import { deDE as pickersDeDE } from '@mui/x-date-pickers/locales';

export const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  gridDeDE,
  pickersDeDE,
  coreDeDE
);
