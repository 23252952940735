import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useGetBundleSummaryQuery } from '../api';

interface EventPositionInfoBoxProps {
  eventPositionId?: number;
  open: boolean;
  onClose: () => void;
}

const EventPositionInfoBox: React.FC<EventPositionInfoBoxProps> = ({ eventPositionId, open, onClose }) => {
  const { data: positionSummary } = useGetBundleSummaryQuery(eventPositionId!, { skip: !eventPositionId || !open });

  const onCopy = () => {
    if (!positionSummary) return;
    navigator.clipboard.writeText(positionSummary?.summaryString);
    enqueueSnackbar('Zusammenfassung kopiert.', { variant: 'success' });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          maxWidth: '450px',
        },
      }}
    >
      <DialogTitle>Zusammenfassung Auftritt</DialogTitle>
      <DialogContent>
        <TextField
          value={positionSummary?.summaryString}
          fullWidth
          multiline
          rows={20}
          variant="outlined"
          InputProps={{ readOnly: true }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCopy} disabled={!positionSummary}>
          Text kopieren
        </Button>
        <Button onClick={onClose}>Schließen</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventPositionInfoBox;
