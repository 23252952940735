import { BaseQueryFn, FetchArgs, FetchBaseQueryError, fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { RootState } from '../_app/store';
import { tokenReset } from './slice';
import apiSlice from '../_app/api';

export const baseUrl =
  process.env.NODE_ENV === 'production'
    ? `https://${process.env.REACT_APP_BACKEND_HOST}/api`
    : `http://${process.env.REACT_APP_BACKEND_HOST}:${process.env.REACT_APP_BACKEND_PORT}`;

const baseQueryWithAuth = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.accessToken;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  let result = await baseQueryWithAuth(args, api, extraOptions);
  if (result.error && result.error.status === 401 && (api.getState() as RootState).auth.accessToken) {
    api.dispatch(tokenReset());
    api.dispatch(apiSlice.util.resetApiState());
  }
  return result;
};

export default baseQueryWithReAuth;
