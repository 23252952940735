import { createApi } from '@reduxjs/toolkit/query/react';
import authBaseQuery from '../auth/authBaseQuery';

const api = createApi({
  reducerPath: 'api',
  baseQuery: authBaseQuery,
  tagTypes: ['Customers', 'Services', 'Events', 'EventPositions'],
  endpoints: (_) => ({}),
});

export default api;