import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Sections from '../_app/Sections';
import { useAuthValidation } from '../auth/api';
import NotFoundPage from '../common/NotFoundPage';
import Modules from './Modules';
import Header from './components/Header';

function AppSection() {
  const isAuthenticated = useAuthValidation();

  // Auth guard
  if (!isAuthenticated) {
    return <Navigate to={Sections.Public} replace={true} />;
  }

  // Routes
  const pathToIndex = Object.values(Modules)[0].path as string;
  const routes = Object.values(Modules).map((module, index) => {
    let path = module.path;
    if (Object.values(module.pages).length > 0) {
      path += '/*';
    }
    return <Route key={index} path={path} element={module.component} />;
  });

  return (
    <React.Fragment>
      <Header />
      <Grid2 padding={2} container>
        <Routes>
          <Route index element={<Navigate to={pathToIndex} replace={true} />} />
          {routes.map((route) => route)}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Grid2>
    </React.Fragment>
  );
}

export default AppSection;
