import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useSearchParams } from 'react-router-dom';
import CustomerControlCard from './components/CustomerControlCard';
import CustomersTable from './components/CustomersTable';

function CustomersModule() {
  const [searchParams, setSearchParams] = useSearchParams();
  
  const selectedCustomerId = searchParams.get('id') ? parseInt(searchParams.get('id')!) : undefined;
  const setSelectedCustomerId = (customerId: number | undefined) => {
    if (customerId) {
      searchParams.set('id', customerId.toString());
    } else {
      searchParams.delete('id');
    }
    setSearchParams(searchParams);
  }

  return (
    <Grid2 container xs={12} justifyContent="space-between" maxHeight="100%">
      <Grid2 xs={9} paddingRight={2}>
        <CustomersTable selectedEntityId={selectedCustomerId} setSelectedEntityId={setSelectedCustomerId} />
      </Grid2>
      <Grid2 xs={3}>
        <CustomerControlCard selectedEntityId={selectedCustomerId} setSelectedEntityId={setSelectedCustomerId} />
      </Grid2>
    </Grid2>
  );
}

export default CustomersModule;
