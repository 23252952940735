import { CopyAll, FolderCopyOutlined, Info } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  OutlinedInput,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import Select from 'react-select';
import { calculateVATFromGross, calculateVATFromNet } from '../../common/vatCalculations';
import { useGetCustomerQuery, useGetCustomersQuery } from '../../customers/api';
import { CustomerType } from '../../customers/types';
import { useGetServicesQuery } from '../../services/api';
import { ServiceType } from '../../services/types';
import { useBatchUpdateStatusMutation, useGenerateInvoiceIdMutation, useUpdateEventMutation } from '../api';
import { Event, EventBundle, EventPosition, EventPositionStatus } from '../types';
import { cleanUpDateString } from '../utils';
import { ContractGenerationButton, InvoiceGenerationButton, OfferGenerationButton } from './DocGenerationButton';
import EventPositionInfoBox from './EventPositionInfoBox';

export type EventPositionFormProps = {
  fromEntity?: Event;
  disabled?: boolean;
};

const FIELD_VARIANT = 'outlined';

const EventPositionForm = (props: EventPositionFormProps) => {
  // State
  const [isServicesDialogOpen, setServicesDialogOpen] = useState(false);
  const [eventPosition, setEventPosition] = useState<Partial<EventPosition>>(props.fromEntity?.position || {});
  const [eventBundle, setEventBundle] = useState<Partial<EventBundle>>(props.fromEntity?.bundle || {});
  React.useEffect(() => {
    setEventPosition(props.fromEntity?.position || {});
    setEventBundle(props.fromEntity?.bundle || {});
  }, [props.fromEntity]);
  const [infoBoxOpen, setInfoBoxOpen] = React.useState(false);

  // Data
  const { data: services, isFetching: isLoadingServices } = useGetServicesQuery();
  const { data: customers, isFetching: isLoadingCustomers } = useGetCustomersQuery();
  const { data: selectedCustomer, isFetching: isLoadingCustomer } = useGetCustomerQuery(eventBundle?.customerId!, {
    skip: !eventBundle?.customerId,
  });
  const [updateEvent] = useUpdateEventMutation();
  const [batchUpdateStatus] = useBatchUpdateStatusMutation();
  const [generateInvoiceId] = useGenerateInvoiceIdMutation();

  // UI state
  const loading = isLoadingServices || isLoadingCustomers || isLoadingCustomer || false;
  const disabled = props.disabled || loading || false;

  // Services dialog controls
  const onSelectFromServices = () => {
    setServicesDialogOpen(true);
  };

  const onServiceSelected = (event: SelectChangeEvent) => {
    if (!services) return;

    setServicesDialogOpen(false);
    const serviceId = Number(event.target.value);
    const service = services.find((service) => service.id === serviceId);
    if (service) {
      if (service.price_gross !== undefined && service.price_gross !== null) {
        setEventPosition({
          ...eventPosition,
          name: service.name,
          type: service.type,
          duration: service.duration,
          description: service.description,
          ...calculateVATFromGross(service.price_gross),
        });
      } else {
        setEventPosition({
          ...eventPosition,
          name: service.name,
          type: service.type,
          duration: service.duration,
          description: service.description,
        });
      }
    }
  };

  const onServicesDialogClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
    if (reason !== 'backdropClick') {
      setServicesDialogOpen(false);
    }
  };

  // Form controls
  const onEventPositionChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }> | SelectChangeEvent | SelectChangeEvent<number>
  ) => {
    const name = event.target.name as keyof typeof eventPosition;

    let newEventPosition;

    if (name === 'price_net') {
      newEventPosition = { ...eventPosition, ...calculateVATFromNet(event.target.value as number) };
    } else if (name === 'price_gross') {
      newEventPosition = { ...eventPosition, ...calculateVATFromGross(event.target.value as number) };
    } else {
      newEventPosition = { ...eventPosition, [name]: event.target.value };
    }
    setEventPosition(newEventPosition);

    if (name === 'status') {
      onSave(newEventPosition);
      if (event.target.value === EventPositionStatus.CONTRACT_SENT) {
        setInfoBoxOpen(true);
      }
    }
  };

  const onEventCustomerChange = (changeEvent: { value: number; label: string } | null) => {
    if (changeEvent) {
      setEventBundle({ ...eventBundle, customerId: changeEvent.value });
      setEventPosition({ ...eventPosition, place: '', address: '', zip_code: '', city: '' });
    }
  };

  const onCopyCustomerAddress = () => {
    if (!selectedCustomer) return;
    setEventPosition({
      ...eventPosition,
      place: selectedCustomer.name,
      address: selectedCustomer.address,
      zip_code: selectedCustomer.zip_code,
      city: selectedCustomer.city,
    });
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSave(eventPosition);
  };

  const onSave = async (position: Partial<EventPosition>) => {
    return new Promise<Event>((resolve, reject) => {
      if (!props.fromEntity) {
        return;
      }

      // Clean up event date by padding with zeros
      let cleanedEventPosition = { ...position };
      if (cleanedEventPosition.date) {
        try {
          cleanedEventPosition.date = cleanUpDateString(cleanedEventPosition.date);
        } catch (e) {}
      }
      setEventPosition(cleanedEventPosition);

      // Update event
      try {
      updateEvent({ positionId: props.fromEntity.position.id, bundle: eventBundle, position: cleanedEventPosition })
        .unwrap()
        .then((result) => {
          enqueueSnackbar(`Position wurde gespeichert.`, {
            variant: 'success',
          });
          resolve(result.updatedEvent);
        })
        .catch((error) => {
          enqueueSnackbar('Beim Speichern ist ein Fehler aufgetreten.', { variant: 'error' });
          reject();
        });
      } catch (e) {
        console.log(e)
      }
    });
  };

  // Docs

  const beforeDocCreation = async () => {
    return onSave(eventPosition);
  };

  const onOfferSuccess = () => {
    if (!props.fromEntity) return;

    batchUpdateStatus({ bundleId: props.fromEntity?.bundle.bundleId, type: 'offer' })
      .unwrap()
      .catch(() => {
        enqueueSnackbar('Beim Speichern ist ein Fehler aufgetreten.', { variant: 'error' });
      });
  };

  const onContractSuccess = () => {
    if (!props.fromEntity) return;

    batchUpdateStatus({ bundleId: props.fromEntity?.bundle.bundleId, type: 'contract' })
      .unwrap()
      .then(() => {
        setInfoBoxOpen(true);
      })
      .catch(() => {
        enqueueSnackbar('Beim Speichern ist ein Fehler aufgetreten.', { variant: 'error' });
      });
  };

  const beforeInvoiceCreation = async () => {
    if (!props.fromEntity) return;

    // Generate invoice id
    try {
      const updatedEvent = await beforeDocCreation();

      const result = await generateInvoiceId(props.fromEntity.bundle.bundleId).unwrap();
      if (result.positionIds.length === 0) {
        enqueueSnackbar(`Vorhandene Rechnungsnummer '${result.invoiceId}' wurde übernommen.`, { variant: 'success' });
      } else {
        enqueueSnackbar(`Rechnungsnummer '${result.invoiceId}' wurde automatisch generiert.`, { variant: 'success' });
      }
      return {invoiceId: result.invoiceId, updatedEvent};
    } catch (e: any) {
      enqueueSnackbar(e.data.message, { variant: 'error' });
      return undefined;
    }
  };

  const onInvoiceSuccess = () => {
    if (!props.fromEntity) return;

    batchUpdateStatus({ bundleId: props.fromEntity?.bundle.bundleId, type: 'invoice' })
      .unwrap()
      .catch(() => {
        enqueueSnackbar('Beim Speichern ist ein Fehler aufgetreten.', { variant: 'error' });
      });
  };

  // Customer select options
  const selectOptions = customers?.map((customer) => ({
    value: customer.id,
    label: customer.type === CustomerType.Business ? `#${customer.id} - Firma ${customer.name}` : `#${customer.id} - ${customer.name}`,
  }));
  const selectedValue = selectOptions?.find((option) => option.value === eventBundle?.customerId);
  var mainContact = undefined;
  if (selectedCustomer?.type) {
    const mainContacts = selectedCustomer.contacts.find((contact) => contact.isMainContact);
    mainContact = mainContacts ? mainContacts.name : selectedCustomer.contacts[0]?.name;
  }

  return (
    <React.Fragment>
      <form noValidate autoComplete="off" onSubmit={onSubmit}>
        <Grid2 direction="column" container rowSpacing={2}>
          <Grid2 xs={12}>
            <FormControl fullWidth disabled={disabled} style={{ zIndex: '10' }}>
              <Select
                placeholder="Kunde"
                isLoading={isLoadingCustomers}
                options={selectOptions}
                isDisabled={disabled}
                value={selectedValue}
                onChange={onEventCustomerChange}
              />
            </FormControl>
          </Grid2>
          {selectedCustomer?.type === CustomerType.Business && (
            <Grid2 xs={12}>
              <TextField
                variant={FIELD_VARIANT}
                fullWidth
                size="small"
                label="Hauptansprechpartner"
                value={mainContact || ''}
                inputProps={{ readOnly: true }}
              />
            </Grid2>
          )}
          <Grid2>
            <Divider />
          </Grid2>
          <Grid2 xs={12}>
            <Button
              variant="text"
              disabled={disabled || !selectedCustomer}
              color="secondary"
              size="small"
              startIcon={<CopyAll />}
              onClick={onCopyCustomerAddress}
            >
              Adresse vom Kunden übernehmen
            </Button>
          </Grid2>
          <Grid2 xs={12}>
            <TextField
              variant={FIELD_VARIANT}
              fullWidth
              size="small"
              label="Ort"
              name="place"
              value={eventPosition.place || ''}
              onChange={onEventPositionChange}
              disabled={disabled}
            />
          </Grid2>
          <Grid2 xs={12}>
            <TextField
              variant={FIELD_VARIANT}
              fullWidth
              size="small"
              label="Addresse"
              name="address"
              value={eventPosition.address || ''}
              onChange={onEventPositionChange}
              disabled={disabled}
            />
          </Grid2>
          <Grid2 xs={12} container columnSpacing={1}>
            <Grid2 xs={4}>
              <TextField
                variant={FIELD_VARIANT}
                fullWidth
                size="small"
                label="Postleitzahl"
                name="zip_code"
                value={eventPosition.zip_code || ''}
                onChange={onEventPositionChange}
                disabled={disabled}
              />
            </Grid2>
            <Grid2 xs={8}>
              <TextField
                variant={FIELD_VARIANT}
                fullWidth
                size="small"
                label="Stadt"
                name="city"
                value={eventPosition.city || ''}
                onChange={onEventPositionChange}
                disabled={disabled}
              />
            </Grid2>
          </Grid2>
          <Grid2 xs={12} container columnSpacing={1}>
            <Grid2 xs={4}>
              <TextField
                variant={FIELD_VARIANT}
                fullWidth
                size="small"
                label="Datum"
                value={eventPosition.date || ''}
                onChange={onEventPositionChange}
                name="date"
                disabled={disabled}
              />
            </Grid2>
            <Grid2 xs={4}>
              <TextField
                variant={FIELD_VARIANT}
                fullWidth
                size="small"
                label="Von"
                value={eventPosition.start || ''}
                onChange={onEventPositionChange}
                name="start"
                disabled={disabled}
              />
            </Grid2>
            <Grid2 xs={4}>
              <TextField
                variant={FIELD_VARIANT}
                fullWidth
                size="small"
                label="Bis"
                value={eventPosition.end || ''}
                onChange={onEventPositionChange}
                name="end"
                disabled={disabled}
              />
            </Grid2>
          </Grid2>
          <Grid2>
            <Divider />
          </Grid2>
          <Grid2 xs={12}>
            <Button
              variant="text"
              color="secondary"
              size="small"
              startIcon={<FolderCopyOutlined />}
              onClick={onSelectFromServices}
              disabled={disabled}
            >
              Aus Services übernehmen
            </Button>
          </Grid2>
          <Grid2 xs={12} container columnSpacing={1}>
            <Grid2 xs={6}>
              <TextField
                variant={FIELD_VARIANT}
                fullWidth
                size="small"
                label="Name"
                value={eventPosition.name || ''}
                onChange={onEventPositionChange}
                name="name"
                disabled={disabled}
              />
            </Grid2>
            <Grid2 xs={6}>
              <FormControl fullWidth>
                <MuiSelect
                  labelId="type-label"
                  id="type"
                  value={eventPosition?.type || ''}
                  onChange={onEventPositionChange}
                  name="type"
                  variant="outlined"
                  size="small"
                  disabled={disabled}
                >
                  <MenuItem value={ServiceType.Event}>Auftritt</MenuItem>
                  <MenuItem value={ServiceType.EventService}>Eventservice</MenuItem>
                </MuiSelect>
              </FormControl>
            </Grid2>
          </Grid2>
          <Grid2>
            <TextField
              variant={FIELD_VARIANT}
              fullWidth
              size="small"
              label="Dauer"
              value={eventPosition.duration || ''}
              onChange={onEventPositionChange}
              name="duration"
              disabled={disabled}
            />
          </Grid2>
          <Grid2>
            <TextField
              variant={FIELD_VARIANT}
              fullWidth
              multiline
              rows={4}
              size="small"
              label="Beschreibung"
              value={eventPosition.description || ''}
              onChange={onEventPositionChange}
              name="description"
              disabled={disabled}
            />
          </Grid2>
          <Grid2 xs={12} container columnSpacing={1}>
            <Grid2 xs={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                type="number"
                value={eventPosition?.price_net || ''}
                name="price_net"
                label="Netto"
                onChange={onEventPositionChange}
                disabled={disabled}
                InputProps={{
                  endAdornment: <InputAdornment position="end">€</InputAdornment>,
                }}
              />
            </Grid2>
            <Grid2 xs={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                type="number"
                value={eventPosition?.price_vat || ''}
                name="price_vat"
                label="MWSt"
                disabled={true}
                onChange={onEventPositionChange}
                InputProps={{
                  endAdornment: <InputAdornment position="end">€</InputAdornment>,
                }}
              />
            </Grid2>
            <Grid2 xs={4}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                type="number"
                value={eventPosition?.price_gross || ''}
                name="price_gross"
                label="Brutto"
                disabled={disabled}
                onChange={onEventPositionChange}
                InputProps={{
                  endAdornment: <InputAdornment position="end">€</InputAdornment>,
                }}
              />
            </Grid2>
          </Grid2>
          <Grid2 xs={12}>
            <TextField
              variant={FIELD_VARIANT}
              fullWidth
              size="small"
              label="Rechnungsnummer"
              name="invoiceId"
              value={eventPosition?.invoiceId || ''}
              onChange={onEventPositionChange}
              disabled={disabled}
            />
          </Grid2>
          <Grid2>
            <Divider />
          </Grid2>
          <Grid2>
            <MuiSelect
              labelId="status-label"
              id="status"
              fullWidth
              value={eventPosition?.status || ''}
              onChange={onEventPositionChange}
              name="status"
              variant="outlined"
              size="small"
              disabled={disabled}
            >
              {Object.values(EventPositionStatus).map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </MuiSelect>
          </Grid2>
          <Grid2>
            <Divider />
          </Grid2>
          <Grid2 xs={12}>
            <LoadingButton type="submit" name="submit" variant="contained" fullWidth loading={loading} disabled={disabled}>
              Speichern
            </LoadingButton>
          </Grid2>
          <Grid2 xs={12}>
            <Divider />
          </Grid2>
          <Grid2 xs={12}>
            <OfferGenerationButton
              event={props.fromEntity}
              disabled={
                props.fromEntity
                  ? !(
                      props.fromEntity?.position.status === EventPositionStatus.DRAFT ||
                      props.fromEntity?.position.status === EventPositionStatus.OFFER_SENT
                    )
                  : true
              }
              beforeSubmit={beforeDocCreation}
              onSuccess={onOfferSuccess}
            />
          </Grid2>
          <Grid2 xs={12}>
            <ContractGenerationButton
              event={props.fromEntity}
              disabled={
                props.fromEntity
                  ? !(
                      props.fromEntity?.position.status === EventPositionStatus.OFFER_ACCEPTED ||
                      props.fromEntity?.position.status === EventPositionStatus.CONTRACT_SENT
                    )
                  : true
              }
              beforeSubmit={beforeDocCreation}
              onSuccess={onContractSuccess}
            />
          </Grid2>
          <Grid2 xs={12}>
            <InvoiceGenerationButton
              event={props.fromEntity}
              disabled={
                props.fromEntity
                  ? !(
                      props.fromEntity?.position.status === EventPositionStatus.CONTRACT_SENT ||
                      props.fromEntity?.position.status === EventPositionStatus.CONTRACT_RECEIVED ||
                      props.fromEntity?.position.status === EventPositionStatus.COMPLETED
                    )
                  : true
              }
              beforeSubmit={beforeInvoiceCreation}
              onSuccess={onInvoiceSuccess}
            />
          </Grid2>
          <Grid2 xs={12}>
            <Button variant="outlined" fullWidth onClick={() => setInfoBoxOpen(true)} color="primary" startIcon={<Info />}>
              Infobox
            </Button>
          </Grid2>
        </Grid2>
      </form>
      <EventPositionInfoBox eventPositionId={props.fromEntity?.position.id} open={infoBoxOpen} onClose={() => setInfoBoxOpen(false)} />
      {services && (
        <Dialog disableEscapeKeyDown open={isServicesDialogOpen} onClose={onServicesDialogClose}>
          <DialogTitle>Wähle einen Service aus</DialogTitle>
          <DialogContent>
            <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <FormControl sx={{ m: 1, minWidth: 240 }}>
                <InputLabel>Service</InputLabel>
                <MuiSelect onChange={onServiceSelected} input={<OutlinedInput label="Service" />}>
                  {services.map((service) => (
                    <MenuItem key={service.id} value={service.id}>
                      {service.name}
                    </MenuItem>
                  ))}
                </MuiSelect>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onServicesDialogClose}>Abbrechen</Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default EventPositionForm;
