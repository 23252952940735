import { EventPositionStatus } from './types';

export const eventPositionStatusToBackgroundColour = (status: EventPositionStatus) => {
  switch (status) {
    case EventPositionStatus.DRAFT:
      return 'yellow';
    case EventPositionStatus.OFFER_SENT:
      return 'orange';
    case EventPositionStatus.OFFER_ACCEPTED:
      return 'red';
    case EventPositionStatus.OFFER_DECLINED:
      return 'black';
    case EventPositionStatus.CHOSE_OTHER:
      return 'darkblue';
    case EventPositionStatus.CONTRACT_SENT:
      return 'lightgreen';
    case EventPositionStatus.CONTRACT_RECEIVED:
      return 'darkgreen';
    case EventPositionStatus.COMPLETED:
      return 'blue';
    case EventPositionStatus.CANCELLED:
      return 'brown';
  }
};

export function cleanUpDateString(dateString: string) {
  let parts = dateString.split(/[-.\/]/); // split by -, ., or /

  let day = parseInt(parts[0], 10);
  let month = parseInt(parts[1], 10) - 1; // subtract 1 for 0-based months
  if (parts[2].length == 2) {
    // 2-digit year
    parts[2] = '20' + parts[2];
  }
  let year = parseInt(parts[2], 10);

  if (isNaN(day) || isNaN(month) || isNaN(year) || day < 1 || day > 31 || month < 0 || month > 11) {
    throw new Error('Invalid date format provided.');
  }

  let date = new Date(year, month, day);

  let dayString = ('0' + date.getDate()).slice(-2); // ensure 2 digits
  let monthString = ('0' + (date.getMonth() + 1)).slice(-2); // ensure 2 digits, +1 because we need 1-based months
  let yearString = date.getFullYear().toString(); // 4-digit year

  let formattedDate = `${dayString}.${monthString}.${yearString}`;

  return formattedDate;
}
