import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './_app/App';
import store, { persistor } from './_app/store';
import { theme } from './_app/theme';
import './index.css';
import 'dayjs/locale/de';

const confirmDefaultOptions = {
  content: 'Diese Aktion ist permanent und kann nicht rückgängig gemacht werden.',
  confirmationText: 'Bestätigen',
  cancellationText: 'Abbrechen',
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <SnackbarProvider maxSnack={3}>
              <ConfirmProvider defaultOptions={confirmDefaultOptions}>
                <App />
              </ConfirmProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);
