import { GridSortModel } from '@mui/x-data-grid';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';

export interface EventsUIState {
  servicesSortingModel: GridSortModel;
}

const initialState: EventsUIState = {
  servicesSortingModel: [],
};

const servicesUISlice = createSlice({
  name: 'services.UI',
  initialState,
  reducers: {
    servicesSorted(state, action: PayloadAction<GridSortModel>) {
      state.servicesSortingModel = action.payload;
    },
  },
});

const servicesUIPersistConfig = {
  key: 'services.UI',
  storage,
  stateReconciler: autoMergeLevel2,
};

const servicesUIReducer = persistReducer<EventsUIState>(servicesUIPersistConfig, servicesUISlice.reducer);

export const { servicesSorted } = servicesUISlice.actions;

export default servicesUIReducer;
