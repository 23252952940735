import { useDispatch, useSelector } from 'react-redux';
import api from '../_app/api';
import { RootState } from '../_app/store';
import { tokenReceived, tokenReset } from './slice';
import jwt from 'jwt-decode';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ username, password }) => ({
        url: '/auth/login',
        method: 'POST',
        body: {
          username,
          password,
        },
      }),
    }),
    changePassword: builder.mutation({
      query: ({ oldPassword, newPassword }) => ({
        url: '/users/password',
        method: 'PATCH',
        body: {
          oldPassword,
          newPassword,
        },
      }),
    }),
  }),
});

const { useLoginMutation, useChangePasswordMutation } = extendedApi;

const useLogin = () => {
  const [loginQuery, { isLoading, isError, error, isSuccess, status }] = useLoginMutation();
  const dispatch = useDispatch();

  const login = async (username: string, password: string) => {
    try {
      let result = await loginQuery({ username, password }).unwrap();
      dispatch(tokenReceived(result.accessToken));
    } catch (error) {
      throw error;
    }
  };

  return {
    login,
    isLoading,
    isError,
    error,
    isSuccess,
    status,
  };
};

const useLogout = () => {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(tokenReset());
    dispatch(api.util.resetApiState());
  };

  return logout;
};

const useAccessToken = () => {
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);
  return accessToken;
};

const useAuthValidation = () => {
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);
  return accessToken !== undefined;
};

const useUsername = () => {
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);
  if (accessToken === undefined) return undefined;

  const payload: any = jwt(accessToken);
  if (!('username' in payload)) return undefined;

  return payload.username as string;
};

export { useLogin, useLogout, useAccessToken, useAuthValidation, useChangePasswordMutation, useUsername };
