export enum CustomerType {
  Business = 'Firma',
  Person = 'Person',
}

export enum PaymentType {
  Cash = 'Bar',
  Invoice = 'Rechnung',
}

export type CustomerContact = {
  id?: number;
  name: string;
  phoneNumber: string;
  email: string;
  isMainContact: boolean;
};

export type Customer = {
  id: number;
  type: CustomerType;
  name: string;
  address: string;
  zip_code: string;
  city: string;
  email: string;
  phoneNumber1: string;
  phoneNumber2: string;
  info?: string;
  paymentType: PaymentType;
  contacts: CustomerContact[];
};

export type UpdateCustomerDto = Partial<Omit<Customer, 'id'>> & { id: number };