import { AppBar, Button, Toolbar } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { enqueueSnackbar } from 'notistack';
import { useLogout } from '../../auth/api';
import Modules from '../Modules';
import NavMenuButton from './NavMenuButton';

const Header = () => {
  const logout = useLogout();

  // Handle logout
  const onLogout = () => {
    logout();
    enqueueSnackbar('Logout erfolgreich', { variant: 'success' });
  };

  return (
    <AppBar position="sticky">
      <Toolbar>
        <Grid2 container justifyContent="space-between" width="100%">
          <Grid2 container spacing={2}>
            {Object.values(Modules).map((module, index) => (
              <Grid2 key={index}>
                <NavMenuButton key={index} module={module} />
              </Grid2>
            ))}
          </Grid2>
          <Grid2>
            <Button color="inherit" onClick={onLogout}>
              Logout
            </Button>
          </Grid2>
        </Grid2>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
