import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

export interface AuthState {
  accessToken?: string;
}

const initialState: AuthState = {
  accessToken: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    tokenReceived(state, action: PayloadAction<string>) {
      state.accessToken = action.payload;
    },
    tokenReset(state) {
      state.accessToken = undefined;
    },
  },
});

const authPersistConfig = {
  key: 'auth',
  storage,
  stateReconciler: autoMergeLevel2,
};

const authReducer = persistReducer<AuthState>(authPersistConfig, authSlice.reducer);

export const { tokenReceived, tokenReset } = authSlice.actions;

export default authReducer;
