import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';

type LocationBoxProps = {
  location: string;
};

const LocationBox: React.FC<React.PropsWithChildren<LocationBoxProps>> = ({ location, children }) => {
  const currentLocation = useLocation();

  const isActive = currentLocation.pathname.startsWith('/app/' + location);

  return (
    <Box
      sx={{
        borderBottom: isActive ? '2px solid currentColor' : 'none',
      }}
    >
      {children}
    </Box>
  );
};

export default LocationBox;
