import { GridColumnVisibilityModel, GridSortModel } from '@mui/x-data-grid';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';

export interface CustomersUIState {
  customersSortingModel: GridSortModel;
  customersVisibilityModel: GridColumnVisibilityModel;
}

const initialState: CustomersUIState = {
  customersSortingModel: [],
  customersVisibilityModel: {'info': false, 'paymentType': false},
};

const customersUISlice = createSlice({
  name: 'customers.UI',
  initialState,
  reducers: {
    customersSorted(state, action: PayloadAction<GridSortModel>) {
      state.customersSortingModel = action.payload;
    },
    customersColumnVisibilityChanged(state, action: PayloadAction<GridColumnVisibilityModel>) {
      state.customersVisibilityModel = action.payload;
    }
  },
});

const customersUIPersistConfig = {
  key: 'customers.UI',
  storage,
  stateReconciler: autoMergeLevel2,
};

const customersUIReducer = persistReducer<CustomersUIState>(customersUIPersistConfig, customersUISlice.reducer);

export const { customersSorted, customersColumnVisibilityChanged } = customersUISlice.actions;

export default customersUIReducer;
