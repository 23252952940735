import api from '../_app/api';
import { CreateEventDto, CreateEventPositionDto, Event, UpdateEventDto } from './types';

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query<Event[], void>({
      query: () => '/events',
      providesTags: (result) =>
        result
          ? [...result.map((event: Event) => ({ type: 'Events' as const, id: event.position.id })), { type: 'Events', id: 'LIST' }]
          : [{ type: 'Events', id: 'LIST' }],
    }),
    getEvent: builder.query<Event, number>({
      query: (positionId: number) => `/events/${positionId}`,
      providesTags: (result, error, positionId) => [{ type: 'Events', id: positionId }],
    }),
    addEvent: builder.mutation<number, CreateEventDto>({
      query: (createDto: CreateEventDto) => ({
        url: '/events',
        method: 'POST',
        body: createDto,
      }),
      invalidatesTags: [{ type: 'Events', id: 'LIST' }],
    }),
    addEventPosition: builder.mutation<number, CreateEventPositionDto>({
      query: ({ bundleId, ...createDto }: CreateEventPositionDto) => ({
        url: `/events/${bundleId}`,
        method: 'POST',
        body: createDto,
      }),
      invalidatesTags: [{ type: 'Events', id: 'LIST' }],
    }),
    updateEvent: builder.mutation<{ positionIds: number[], updatedEvent: Event }, UpdateEventDto>({
      query: ({ positionId, ...updateDto }: UpdateEventDto) => ({
        url: `/events/${positionId}`,
        method: 'PUT',
        body: updateDto,
      }),
      invalidatesTags: (result, error) =>
        result ? [...result.positionIds.map((posId) => ({ type: 'Events' as const, id: posId }))] : [{ type: 'Events', id: 'LIST' }],
    }),
    deleteEventPosition: builder.mutation<void, number>({
      query: (positionId: number) => ({
        url: `/events/${positionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, positionId) => [{ type: 'Events', id: positionId }],
    }),
    batchUpdateStatus: builder.mutation<number[], { bundleId: number; type: string }>({
      query: ({ bundleId, type }: { bundleId: number; type: string }) => ({
        url: `/events/batchStatus/${type}/${bundleId}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error) =>
        result ? [...result.map((posId) => ({ type: 'Events' as const, id: posId }))] : [{ type: 'Events', id: 'LIST' }],
    }),
    generateInvoiceId: builder.mutation<{ positionIds: number[], invoiceId: number}, number>({
      query: (bundleId: number) => ({
        url: `/events/invoice/${bundleId}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error) =>
        result ? [...result.positionIds.map((posId) => ({ type: 'Events' as const, id: posId }))] : [{ type: 'Events', id: 'LIST' }],
    }),
    getBundleSummary: builder.query<any, number>({
      query: (positionId: number) => `/events/summary/${positionId}`,
      providesTags: (result, error, positionId) => [{ type: 'Events', id: positionId }],
    }),
  }),
});

export const {
  useGetEventsQuery,
  useGetEventQuery,
  useAddEventMutation,
  useAddEventPositionMutation,
  useUpdateEventMutation,
  useDeleteEventPositionMutation,
  useBatchUpdateStatusMutation,
  useGenerateInvoiceIdMutation,
  useGetBundleSummaryQuery,
} = extendedApi;
