export enum ServiceType {
  Event = 'Auftritt',
  EventService = 'Eventservice',
}

export type Service = {
  id: number;
  name: string;
  type: ServiceType;
  duration: string;
  description: string;
  price_gross: number;
};

export type UpdateServiceDto = Partial<Omit<Service, 'id'>> & { id: number };