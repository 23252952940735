import { Navigate } from 'react-router-dom';
import Sections from '../_app/Sections';
import { useAuthValidation, useLogin } from '../auth/api';

import { LoadingButton } from '@mui/lab';
import { Container, Paper, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';

function PublicSection() {
  const isAuthenticated = useAuthValidation();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login, isLoading } = useLogin();

  if (isAuthenticated) {
    return <Navigate to={Sections.App} replace={true} />;
  }

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      await login(username, password);
      enqueueSnackbar(`Login erfolgreich.`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(`Login fehlgeschlagen.`, { variant: 'error' });
    }
  };

  return (
    <Container maxWidth="xs">
      <Paper elevation={3} sx={{ marginTop: 8 }}>
        <form onSubmit={onSubmit}>
          <Grid2 xs={12} container padding={2} rowSpacing={2}>
            <Grid2
              xs={12}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}
            >
              <Typography variant="h4" component="h1" gutterBottom>
                Login
              </Typography>
            </Grid2>
            <Grid2 xs={12}>
              <TextField
                label="Username"
                type="username"
                color="primary"
                variant="filled"
                fullWidth
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                disabled={isLoading}
              />
            </Grid2>
            <Grid2 xs={12}>
              <TextField
                label="Password"
                type="password"
                color="primary"
                variant="filled"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={isLoading}
              />
            </Grid2>
            <Grid2 xs={12}>
              <LoadingButton fullWidth type="submit" name="submit" variant="contained" color="primary" loading={isLoading}>
                Login
              </LoadingButton>
            </Grid2>
          </Grid2>
        </form>
      </Paper>
    </Container>
  );
}

export default PublicSection;
