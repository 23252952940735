import { Add, Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useConfirm } from 'material-ui-confirm';
import { enqueueSnackbar } from 'notistack';
import { useAddCustomerMutation, useDeleteCustomerMutation, useGetCustomerQuery } from '../api';
import CustomerForm from './CustomerForm';
import { useNavigate } from 'react-router-dom';
import React from 'react';

export interface CustomerControlCardProps {
  selectedEntityId?: number;
  setSelectedEntityId: (customerId: number | undefined) => void;
}

function CustomerControlCard({ selectedEntityId, setSelectedEntityId }: CustomerControlCardProps) {
  // State
  const navigate = useNavigate();
  const confirm = useConfirm();

  // Data
  const [addCustomer, { isLoading: isAdding }] = useAddCustomerMutation();
  const [deleteCustomer, { isLoading: isDeleting }] = useDeleteCustomerMutation();
  const { data: remoteCustomer, isFetching, isSuccess, isError } = useGetCustomerQuery(selectedEntityId!, { skip: !selectedEntityId });
  const customer = selectedEntityId !== undefined ? remoteCustomer : undefined;

  // UI state
  const disabled = !customer || false;

  // Update state if selected entity successfully loaded but empty returned
  React.useEffect(() => {
    if (isError || (!isFetching && isSuccess && !remoteCustomer)) {
      setSelectedEntityId(undefined);
    }
  }, [remoteCustomer, isSuccess, isFetching, isError, setSelectedEntityId]);

  // Modification controls
  const onCustomerAdd = () => {
    addCustomer()
      .unwrap()
      .then((id) => {
        enqueueSnackbar(`Kunde wurde erstellt.`, {
          variant: 'success',
        });
        setSelectedEntityId(id);
      })
      .catch((error) => {
        enqueueSnackbar('Beim Erstellen ist ein Fehler aufgetreten.', { variant: 'error' });
      });
  };

  const onCustomerDelete = () => {
    if (selectedEntityId) {
      confirm({ title: `Kunde '#${customer?.id} - ${customer?.name}' löschen?` }).then(() => {
        deleteCustomer(selectedEntityId)
          .unwrap()
          .then(() => {
            enqueueSnackbar('Kunde erfolgreich gelöscht', { variant: 'success' });
            navigate(-1);
          })
          .catch(() => {
            enqueueSnackbar('Beim Löschen ist ein Fehler aufgetreten.', { variant: 'error' });
          });
      });
    }
  };

  // Render
  return (
    <Box
      sx={{
        borderRadius: '4px',
        border: '1px solid #e0e0e0',
        height: '100%',
        width: '100%',
      }}
    >
      <Grid2 xs={12} container rowSpacing={2} padding={2}>
        <Grid2 xs={12} container justifyContent="space-between">
          <Grid2>
            <Typography variant="h6">{customer ? `Kunde ${customer.id}` : 'Kunde'}</Typography>
          </Grid2>
          <Grid2>
            <LoadingButton variant="outlined" onClick={onCustomerAdd} color="secondary" startIcon={<Add />} loading={isAdding}>
              Neu
            </LoadingButton>
          </Grid2>
        </Grid2>
        <Grid2 xs={12}>
          <CustomerForm fromEntity={customer} disabled={disabled} />
        </Grid2>
        <Grid2 xs={12}>
          <Divider />
        </Grid2>
        <Grid2 xs={12}>
          <LoadingButton
            variant="outlined"
            fullWidth
            onClick={onCustomerDelete}
            color="primary"
            startIcon={<Delete />}
            loading={isDeleting}
            disabled={disabled}
          >
            Löschen
          </LoadingButton>
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default CustomerControlCard;
