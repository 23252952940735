import { Refresh } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../_app/store';
import { useGetServicesQuery } from '../api';
import { servicesSorted } from '../uiSlice';

export interface ServicesTableProps {
  selectedEntityId?: number;
  setSelectedEntityId: (customerId: number | undefined) => void;
}

// Table columns
const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Name', width: 200 },
  { field: 'type', headerName: 'Art', width: 150 },
  { field: 'duration', headerName: 'Dauer', width: 100 },
  { field: 'description', headerName: 'Beschreibung', width: 500 },
  { field: 'price_gross', headerName: 'Preis (brutto)', width: 150 },
];

export function ServicesTable({selectedEntityId, setSelectedEntityId}: ServicesTableProps) {
  const { data: services, isFetching, isError, refetch } = useGetServicesQuery();
  const sortModel = useSelector((state: RootState) => state.servicesUI.servicesSortingModel);
  const dispatch = useDispatch();

  // Handle row selection
  const handleRowSelectionModelChanged = (selectionModel: GridRowSelectionModel) => {
    if (selectionModel.length === 1) {
      setSelectedEntityId(selectionModel[0] as number);
    } else {
      setSelectedEntityId(undefined);
    }
  };

  // Render
  return (
    <Grid2 xs={12} container rowSpacing={1}>
      <Grid2 xs={12}>
        <DataGrid
          rows={services || []}
          columns={columns}
          getRowId={(row) => row.id}
          checkboxSelection={false}
          autoHeight
          rowSelectionModel={selectedEntityId !== undefined ? [selectedEntityId] : []}
          onRowSelectionModelChange={handleRowSelectionModelChanged}
          keepNonExistentRowsSelected
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => dispatch(servicesSorted(newSortModel))}
        />
      </Grid2>
      <Grid2 xs={12} container direction="row" columnSpacing={1} alignItems="center">
        <Grid2>
          <LoadingButton
            variant="text"
            size="small"
            color="info"
            startIcon={<Refresh />}
            onClick={() => setTimeout(refetch, 300)}
            loading={isFetching}
            loadingPosition="start"
          >
            Neu laden
          </LoadingButton>
        </Grid2>
        {isError && (
          <Grid2>
            <Typography variant="body2" color="error">
              Fehler: Services konnten nicht geladen werden.
            </Typography>
          </Grid2>
        )}
      </Grid2>
    </Grid2>
  );
}

export default ServicesTable;
