import { DocumentScannerOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useAccessToken } from '../../auth/api';
import { baseUrl } from '../../auth/authBaseQuery';
import { Event } from '../types';
import { useGetCustomerQuery } from '../../customers/api';
import { CustomerType } from '../../customers/types';

function padID(id: number) {
  let strID = String(id);
  let paddedID = strID.padStart(5, '0');
  return paddedID;
}

function formatDate(string: string) {
  if (!string || string === '') return 'KEIN DATUM';
  let date = string.split('.');
  let year = date[2].slice(2);
  let month = date[1];
  let day = date[0];
  return year + month + day;
}

export type DocGenerationButtonProps = {
  event?: Event;
  disabled?: boolean;
  beforeSubmit: () => Promise<Event>;
  onSuccess: () => void;
};

export type InvoiceGenerationButtonProps = {
  event?: Event;
  disabled?: boolean;
  beforeSubmit: () => Promise<{invoiceId: number, updatedEvent: Event} | undefined>;
  onSuccess: () => void;
};

const OfferGenerationButton = ({ event, disabled, beforeSubmit, onSuccess }: DocGenerationButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = useAccessToken();
  const { data: customer } = useGetCustomerQuery(event?.bundle.customerId!, { skip: !event?.bundle.customerId });

  const bundleId = event?.bundle.bundleId;

  const handleDownload = async () => {
    if (!event || !bundleId || !customer) {
      return;
    }
    setIsLoading(true);

    const updatedEvent = await beforeSubmit();

    try {
      const response = await fetch(`${baseUrl}/docs/offer/${bundleId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status !== 200) {
        const error_response = await response.json();
        if ('message' in error_response) {
          enqueueSnackbar(error_response.message, { variant: 'error' });
        } else {
          enqueueSnackbar('Angebot konnte nicht erstellt werden', { variant: 'error' });
        }
        setIsLoading(false);
        return;
      }

      const blob = await response.blob();

      var contactName = "";
      if (customer.type === CustomerType.Business) {
        const mainContact = customer.contacts.find((contact) => contact.isMainContact);
        if (mainContact) {
          contactName = " " + mainContact.name;
        } else if (customer.contacts.length > 0) {
          contactName = " " + customer.name;
        }
      }

      // Create a download link for the .docx file
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${formatDate(updatedEvent.position.date)} Angebot ${padID(bundleId)} ${customer.name + contactName}.docx`;
      document.body.appendChild(a);
      a.click();
      a.remove();

      onSuccess();
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoadingButton
      variant="outlined"
      fullWidth
      startIcon={<DocumentScannerOutlined />}
      loading={isLoading}
      onClick={handleDownload}
      disabled={disabled}
    >
      Angebot erstellen
    </LoadingButton>
  );
};

const ContractGenerationButton = ({ event, disabled, beforeSubmit, onSuccess }: DocGenerationButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = useAccessToken();
  const { data: customer } = useGetCustomerQuery(event?.bundle.customerId!, { skip: !event?.bundle.customerId });

  const bundleId = event?.bundle.bundleId;

  const handleDownload = async () => {
    if (!event || !bundleId || !customer) {
      return;
    }
    setIsLoading(true);

    const updatedEvent = await beforeSubmit();

    try {
      const response = await fetch(`${baseUrl}/docs/contract/${bundleId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status !== 200) {
        const error_response = await response.json();
        if ('message' in error_response) {
          enqueueSnackbar(error_response.message, { variant: 'error' });
        } else {
          enqueueSnackbar('Vertrag konnte nicht erstellt werden', { variant: 'error' });
        }
        setIsLoading(false);
        return;
      }

      const blob = await response.blob();

      var contactName = "";
      if (customer.type === CustomerType.Business) {
        const mainContact = customer.contacts.find((contact) => contact.isMainContact);
        if (mainContact) {
          contactName = " " + mainContact.name;
        } else if (customer.contacts.length > 0) {
          contactName = " " + customer.name;
        }
      }

      // Create a download link for the .docx file
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${formatDate(updatedEvent.position.date)} Vertrag ${customer.name + contactName}.docx`;
      document.body.appendChild(a);
      a.click();
      a.remove();

      onSuccess();
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoadingButton
      variant="outlined"
      fullWidth
      startIcon={<DocumentScannerOutlined />}
      loading={isLoading}
      onClick={handleDownload}
      disabled={disabled}
    >
      Vertrag erstellen
    </LoadingButton>
  );
};

const InvoiceGenerationButton = ({ event, disabled, beforeSubmit, onSuccess }: InvoiceGenerationButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = useAccessToken();
  const { data: customer } = useGetCustomerQuery(event?.bundle.customerId!, { skip: !event?.bundle.customerId });

  const bundleId = event?.bundle.bundleId;

  const handleDownload = async () => {
    if (!event || !bundleId || !customer) {
      return;
    }
    setIsLoading(true);

    const update = await beforeSubmit();
    if (!update) {
      enqueueSnackbar('Rechnung konnte nicht erstellt werden', { variant: 'error' });
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch(`${baseUrl}/docs/invoice/${bundleId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status !== 200) {
        const error_response = await response.json();
        if ('message' in error_response) {
          enqueueSnackbar(error_response.message, { variant: 'error' });
        } else {
          enqueueSnackbar('Rechnung konnte nicht erstellt werden', { variant: 'error' });
        }
        setIsLoading(false);
        return;
      }

      const blob = await response.blob();

      // Create a download link for the .docx file
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${formatDate(update.updatedEvent.position.date)} Rechnung ${padID(update.invoiceId)} ${customer.name}.docx`;
      document.body.appendChild(a);
      a.click();
      a.remove();

      onSuccess();
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoadingButton
      variant="outlined"
      fullWidth
      startIcon={<DocumentScannerOutlined />}
      loading={isLoading}
      onClick={handleDownload}
      disabled={disabled}
    >
      Rechnung erstellen
    </LoadingButton>
  );
};

export { ContractGenerationButton, InvoiceGenerationButton, OfferGenerationButton };
