import { Add, Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useAddEventMutation } from '../../events/api';
import { useUpdateCustomerMutation } from '../api';
import { Customer, CustomerType, PaymentType } from '../types';

export type CustomerFormProps = {
  fromEntity?: Customer;
  disabled?: boolean;
};

const FIELD_VARIANT = 'outlined';

const CustomerForm = (props: CustomerFormProps) => {
  // State
  const navigate = useNavigate();
  const [customer, setCustomer] = useState<Partial<Customer>>(props.fromEntity || {});
  React.useEffect(() => {
    setCustomer(props.fromEntity || {});
  }, [props.fromEntity]);

  // Data
  const [updateCustomer, { isLoading: isUpdating }] = useUpdateCustomerMutation();
  const [addEvent, { isLoading: isAdding }] = useAddEventMutation();

  // UI state
  const loading = isUpdating || isAdding || false;
  const disabled = props.disabled || loading || false;

  // Form controls
  const onCustomerChange = (event: React.ChangeEvent<{ name?: string; value: unknown }> | SelectChangeEvent) => {
    const name = event.target.name as keyof typeof customer;
    if (name === 'type' && event.target.value === CustomerType.Person) {
      setCustomer({ ...customer, [name]: event.target.value, contacts: [] });
    } else {
      setCustomer({ ...customer, [name]: event.target.value });
    }
  };

  const onContactChange = (index: number, key: 'name' | 'email' | 'phoneNumber' | 'isMainContact', value: string | boolean) => {
    const updatedContacts = [...customer.contacts!];
    updatedContacts[index] = { ...updatedContacts[index], [key]: value };
    if (key === 'isMainContact' && value) {
      updatedContacts.forEach((contact, i) => {
        if (i !== index) {
          updatedContacts[i] = { ...contact, isMainContact: false };
        }
      });
    }
    setCustomer({ ...customer, contacts: updatedContacts });
  };

  const onContactAdd = () => {
    setCustomer({ ...customer, contacts: [...customer.contacts!, { name: '', email: '', phoneNumber: '', isMainContact: false }] });
  };

  function onContactDelete(index: number): void {
    const updatedContacts = [...customer.contacts!];
    updatedContacts.splice(index, 1);
    setCustomer({ ...customer, contacts: updatedContacts });
  }

  const onSave = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!props.fromEntity) {
      return;
    }

    updateCustomer({ ...customer, id: props.fromEntity.id })
      .unwrap()
      .then(() => {
        enqueueSnackbar(`Kunde '${customer.name}' wurde gespeichert.`, { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar('Beim Speichern ist ein Fehler aufgetreten.', { variant: 'error' });
      });
  };

  const onSaveAndCreateEvent = () => {
    if (!props.fromEntity) {
      return;
    }

    updateCustomer({ ...customer, id: props.fromEntity.id })
      .unwrap()
      .then(() => {
        enqueueSnackbar(`Kunde '${customer.name}' wurde gespeichert.`, { variant: 'success' });
        addEvent({ customerId: customer.id! })
          .unwrap()
          .then((id) => {
            enqueueSnackbar(`Auftritt wurde erstellt.`, { variant: 'success' });
            navigate(`../events/?id=${id}`);
          })
          .catch((error) => {
            enqueueSnackbar('Beim Speichern ist ein Fehler aufgetreten.', { variant: 'error' });
          });
      })
      .catch((error) => {
        enqueueSnackbar('Beim Speichern ist ein Fehler aufgetreten.', { variant: 'error' });
      });
  };

  return (
    <form noValidate autoComplete="off" onSubmit={onSave}>
      <Grid2 container rowSpacing={2} xs={12}>
        <Grid2 xs={12}>
          <FormControl fullWidth disabled={disabled} size="small">
            <InputLabel variant={FIELD_VARIANT}>Art</InputLabel>
            <Select variant={FIELD_VARIANT} value={customer.type || ''} onChange={onCustomerChange} label="Type" name="type">
              {Object.values(CustomerType).map((customerType) => (
                <MenuItem key={customerType} value={customerType}>
                  {customerType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid2>
        <Grid2 xs={12}>
          <TextField
            variant={FIELD_VARIANT}
            size="small"
            fullWidth
            label="Name"
            value={customer.name || ''}
            onChange={onCustomerChange}
            name="name"
            disabled={disabled}
          />
        </Grid2>
        <Grid2 xs={12}>
          <Divider />
        </Grid2>
        <Grid2 xs={12}>
          <TextField
            variant={FIELD_VARIANT}
            size="small"
            fullWidth
            label="Adresse"
            value={customer.address || ''}
            onChange={onCustomerChange}
            name="address"
            disabled={disabled}
          />
        </Grid2>
        <Grid2 xs={4} paddingRight={2}>
          <TextField
            variant={FIELD_VARIANT}
            size="small"
            fullWidth
            label="Postleitzahl"
            value={customer.zip_code || ''}
            onChange={onCustomerChange}
            name="zip_code"
            disabled={disabled}
          />
        </Grid2>
        <Grid2 xs={8}>
          <TextField
            variant={FIELD_VARIANT}
            size="small"
            fullWidth
            label="Stadt"
            value={customer.city || ''}
            onChange={onCustomerChange}
            name="city"
            disabled={disabled}
          />
        </Grid2>
        <Grid2 xs={12}>
          <Divider />
        </Grid2>
        <Grid2 xs={12}>
          <TextField
            variant={FIELD_VARIANT}
            size="small"
            fullWidth
            label="Email"
            value={customer.email || ''}
            onChange={onCustomerChange}
            name="email"
            disabled={disabled}
          />
        </Grid2>
        <Grid2 xs={6} paddingRight={1}>
          <TextField
            variant={FIELD_VARIANT}
            size="small"
            fullWidth
            label="Telefonnummer 1"
            value={customer.phoneNumber1 || ''}
            onChange={onCustomerChange}
            name="phoneNumber1"
            disabled={disabled}
          />
        </Grid2>
        <Grid2 xs={6} paddingLeft={1}>
          <TextField
            variant={FIELD_VARIANT}
            size="small"
            fullWidth
            label="Telefonnummer 2"
            value={customer.phoneNumber2 || ''}
            onChange={onCustomerChange}
            name="phoneNumber2"
            disabled={disabled}
          />
        </Grid2>
        <Grid2 xs={12}>
          <Divider />
        </Grid2>
        <Grid2 xs={12}>
          <FormControl fullWidth size="small" disabled={disabled}>
            <InputLabel variant={FIELD_VARIANT}>Bezahlart</InputLabel>
            <Select
              variant={FIELD_VARIANT}
              value={customer.paymentType || ''}
              onChange={onCustomerChange}
              label="Bezahlart"
              name="paymentType"
            >
              {Object.values(PaymentType).map((paymentType) => (
                <MenuItem key={paymentType} value={paymentType}>
                  {paymentType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid2>
        <Grid2 xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            multiline
            rows={3}
            label="Info"
            value={customer.info || ''}
            onChange={onCustomerChange}
            name="info"
            disabled={disabled}
          />
        </Grid2>
        {customer.type === CustomerType.Business && (
          <Grid2 xs={12}>
            <Divider />
          </Grid2>
        )}
        {customer.type === CustomerType.Business &&
          customer.contacts!.map((contact, index) => (
            <React.Fragment key={index}>
              <Grid2 xs={12} container rowSpacing={1} paddingTop={2} paddingBottom={2}>
                <Grid2 xs={12} container columnSpacing={1}>
                  <Grid2 xs={6}>
                    <TextField
                      variant={FIELD_VARIANT}
                      size="small"
                      fullWidth
                      label={`Kontaktperson ${index + 1} Name`}
                      value={contact.name || ''}
                      onChange={(e) => onContactChange(index, 'name', e.target.value)}
                      name={`contactName_${index}`}
                      disabled={disabled}
                    />
                  </Grid2>
                  <Grid2 xs={5}>
                    <FormControlLabel
                      value={index}
                      control={
                        <Radio checked={contact.isMainContact} onChange={(e) => onContactChange(index, 'isMainContact', e.target.checked)} />
                      }
                      label="Hauptkontakt"
                    />
                  </Grid2>
                  <Grid2>
                    <IconButton onClick={() => onContactDelete(index)} disabled={disabled}>
                      <Delete />
                    </IconButton>
                  </Grid2>
                </Grid2>
                <Grid2 xs={12} container columnSpacing={1}>
                  <Grid2 xs={6}>
                    <TextField
                      variant={FIELD_VARIANT}
                      size="small"
                      fullWidth
                      label={`Kontaktperson ${index + 1} Email`}
                      value={contact.email || ''}
                      onChange={(e) => onContactChange(index, 'email', e.target.value)}
                      name={`contactEmail_${index}`}
                      disabled={disabled}
                    />
                  </Grid2>
                  <Grid2 xs={6}>
                    <TextField
                      variant={FIELD_VARIANT}
                      size="small"
                      fullWidth
                      label={`Kontaktperson ${index + 1} Telefon`}
                      value={contact.phoneNumber || ''}
                      onChange={(e) => onContactChange(index, 'phoneNumber', e.target.value)}
                      name={`contactPhoneNumber_${index}`}
                      disabled={disabled}
                    />
                  </Grid2>
                </Grid2>
              </Grid2>
            </React.Fragment>
          ))}
        {customer.type === CustomerType.Business && (
          <Grid2 xs={12}>
            <LoadingButton
              variant="outlined"
              color="secondary"
              startIcon={<Add />}
              fullWidth
              loading={loading}
              disabled={disabled}
              onClick={onContactAdd}
            >
              Kontakt hinzufügen
            </LoadingButton>
          </Grid2>
        )}
        <Grid2 xs={12}>
          <Divider />
        </Grid2>
        <Grid2 xs={12} container rowSpacing={1}>
          <Grid2 xs={12}>
            <LoadingButton variant="contained" color="primary" type="submit" fullWidth loading={isUpdating} disabled={disabled}>
              Speichern
            </LoadingButton>
          </Grid2>
          <Grid2 xs={12}>
            <LoadingButton
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={onSaveAndCreateEvent}
              loading={isUpdating || isAdding}
              disabled={disabled}
            >
              Speichern & Auftritt erstellen
            </LoadingButton>
          </Grid2>
        </Grid2>
      </Grid2>
    </form>
  );
};

export default CustomerForm;
