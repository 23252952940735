import { GridColumnVisibilityModel, GridSortModel } from '@mui/x-data-grid';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';

export interface EventsUIState {
  eventsSortingModel: GridSortModel;
  eventsVisibilityModel: GridColumnVisibilityModel;
}

const initialState: EventsUIState = {
  eventsSortingModel: [{ field: 'aggregatedId', sort: 'desc' }],
  eventsVisibilityModel: {'type': false, 'description': false, 'price_vat': false, 'price_gross': false},
};

const eventsUISlice = createSlice({
  name: 'events.UI',
  initialState,
  reducers: {
    eventsSorted(state, action: PayloadAction<GridSortModel>) {
      state.eventsSortingModel = action.payload;
    },
    eventsColumnVisibilityChanged(state, action: PayloadAction<GridColumnVisibilityModel>) {
      state.eventsVisibilityModel = action.payload;
    }
  },
});

const eventsUIPersistConfig = {
  key: 'events.UI',
  storage,
  stateReconciler: autoMergeLevel2,
};

const eventsUIReducer = persistReducer<EventsUIState>(eventsUIPersistConfig, eventsUISlice.reducer);

export const { eventsSorted, eventsColumnVisibilityChanged } = eventsUISlice.actions;

export default eventsUIReducer;
