import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import apiSlice from './api';
import authReducer from '../auth/slice';
import { persistStore } from 'redux-persist';
import eventsUIReducer from '../events/uiSlice';
import customersUIReducer from '../customers/uiSlice';
import servicesUIReducer from '../services/uiSlice';

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  eventsUI: eventsUIReducer,
  customersUI: customersUIReducer,
  servicesUI: servicesUIReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }).concat(apiSlice.middleware),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
export default store;
