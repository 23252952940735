import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import AppSection from '../app/Section';
import PublicSection from '../public/Section';
import Sections from './Sections';
import NotFoundPage from '../common/NotFoundPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route index element={<PublicSection />} />
        <Route path={`${Sections.App}/*`} element={<AppSection />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
